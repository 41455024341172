<template>
  <v-container class="diary-followers-page diary-page">
    <v-card min-height="300" elevation="1">
      <v-card-title>
        <h2 class="text-h6">{{ $t("Followers") }}</h2>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader :loading="is_loading" type="article">
          <div>
            <template v-if="!error">
              <v-row v-if="items && items.length > 0">
                <v-col
                  cols="12"
                  xs="12"
                  md="4"
                  v-for="item in items"
                  :key="item.id"
                >
                  <v-card outlined>
                    <v-list>
                      <v-list-item>
                        <v-list-item-avatar>
                          <user-avatar :user="item" :size="50"></user-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="username-title">
                            <router-link :to="getProfileUrl(item.username)">{{
                              item.username | capitalize
                            }}</router-link>

                            <icons-helper
                              :blog-admin="isBlogAdmin(item.username)"
                              :is-blocked="item.is_blocked && is_admin"
                            ></icons-helper>
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <button-menu
                            icon
                            :items="getItemMenu(item)"
                            btn-icon="$vuetify.icons.dotsVertical"
                            v-if="is_admin"
                          ></button-menu>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>

                <v-col
                  cols="12"
                  xs="12"
                  class="text-center"
                  v-if="unit_pages > 1"
                >
                  <v-pagination
                    v-model="current_page"
                    :length="unit_pages"
                    :total-visible="7"
                  ></v-pagination>
                </v-col>
              </v-row>
              <v-card-text
                class="text-center"
                v-if="!items || items.length == 0"
                >{{ $t("NoFollowers") }}</v-card-text
              >
            </template>

            <error-page :error="error" v-if="error"></error-page>
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>

    <block-dialog
      v-on:blocked="onBlocked"
      ref="blockUserDialog"
      v-if="is_admin"
    ></block-dialog>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Followers": "Followers",
    "NoFollowers": "No followers to show!",
    "BlockMember": "Block member",
    "SuccessUnblock": "Member unblocked!",
    "UnblockMember": "Unblock member",
    "VisitProfile": "Visit profile"
    },
    "sv": {
    "Followers": "Följare",
    "NoFollowers": "Denna dagbok har inga följare ännu!",
    "BlockMember": "Blockera användare",
    "SuccessUnblock": "Användaren avblockerad!",
    "UnblockMember": "Avblockera användare",
    "VisitProfile": "Besök profil"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { diaryService, blogBlockService } from "@/_services";
const BlockUserDialog = () =>
  import("@/components/diary/dialogs/BlockUserDialog.vue");
import IconsHelper from "@/components/global/IconsHelper.vue";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
export default {
  name: "diary_search",
  metaInfo() {
    return {
      titleTemplate:
        this.$t("Followers") +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content: this.$t("Followers") + " | " + this.diary.title,
          vmid: "og:title",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "block-dialog": BlockUserDialog,
    "icons-helper": IconsHelper,
    "user-avatar": UserAvatar,
  },
  data: () => ({
    error: null,
    is_loading: false,
    items: [],
    current_page: 1,
    unit_pages: 0,
  }),
  created: function () {
    this.setFromUrl();
    this.load();
  },
  methods: {
    ...mapActions({}),

    getItemMenu(item) {
      var self = this;
      var data = [];

      data.push({
        name: this.$t("VisitProfile"),
        icon: "$vuetify.icons.account",
        to: self.getProfileUrl(item.username),
      });

      if (!this.isBlogAdmin(item.username) && !item.is_blocked)
        data.push({
          name: this.$t("BlockMember"),
          icon: "$vuetify.icons.accountCancelOutline",
          click: function () {
            self.blockUser(item.username);
          },
        });

      if (!this.isBlogAdmin(item.username) && item.is_blocked)
        data.push({
          name: this.$t("UnblockMember"),
          icon: "$vuetify.icons.accountCheckOutline",
          click: function () {
            self.unblockUser(item.username);
          },
        });

      return data;
    },
    getTitle() {
      return this.$t("Followers");
    },
    getProfileUrl(username) {
      return "/user/" + username;
    },
    setFromUrl() {
      this.current_page = 1;

      if (this.$route.query.page)
        this.current_page = parseInt(this.$route.query.page);
    },
    load() {
      var self = this;
      self.is_loading = true;
      self.error = null;

      diaryService
        .followers(self.diary.name, self.current_page)
        .then(function (response) {
          self.is_loading = false;
          self.current_page = response.data.page;
          self.unit_pages = response.data.unit_pages;
          self.items = response.data.data;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    blockUser(username) {
      this.$refs.blockUserDialog.blockUser(username);
    },
    unblockUser(username) {
      var self = this;
      blogBlockService
        .deleteUser(self.diary.name, username)
        .then(function () {
          self.$successNoty(self.$t("SuccessUnblock"));
          self.changeUserBlockStatus(username, false);
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
        });
    },
    isBlogAdmin(username) {
      if (this.diary.admins) {
        return this.diary.admins.find((p) => p.username == username) != null;
      }
      return false;
    },
    onBlocked(username) {
      this.changeUserBlockStatus(username, true);
    },
    changeUserBlockStatus(username, isBlocked) {
      this.items.forEach((user) => {
        if (user.username == username) {
          user.is_blocked = isBlocked;
          return;
        }
      });
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.load();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.current_page) return;
      self.$router.push({ query: { page: self.current_page } });
    },
  },
};
</script>

<style scoped>
.username-title a {
  font-weight: 500;
  text-decoration: none;
  color: black;
}
.username-title a:hover {
  text-decoration: underline;
}
</style>