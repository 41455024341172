<template>
  <v-container class="home-diary diary-page">
    <v-row class="mobile-margin-fix mt-0">
      <v-col xs="12" md="3" cols="12" class="mobile-padding-fix">
        <diary-profile></diary-profile>
        <widget-children class="mt-6"></widget-children>
      </v-col>
      <v-col xs="12" md="9" cols="12" class="diary-main mobile-padding-fix">
        <v-card class="pb-6" min-height="600" elevation="1">
          <v-card-title>
            <h2 class="text-h6">{{ $t("Timeline") }}</h2>
            <v-spacer></v-spacer>
            <template v-if="is_admin">
              <v-spacer></v-spacer>

              <button-menu
                depressed
                :items="admin_menu"
                btn-icon="$vuetify.icons.dotsHorizontal"
                v-if="is_admin"
              ></button-menu>
            </template>
          </v-card-title>
          <v-card-text>
            <v-skeleton-loader
              :loading="init_load"
              type="list-item-avatar-three-line"
            >
              <div>
                <template v-if="!error">
                  <v-timeline
                    align-top
                    dense
                    v-if="items && items.length"
                    class="ml-n7 diary-timeline"
                  >
                    <v-timeline-item
                      v-for="(item, i) in items"
                      :key="i"
                      :color="item.icon.bgcolor"
                      fill-dot
                      :class="{ deleting: item.deleting }"
                    >
                      <router-link
                        slot="icon"
                        v-if="!item.icon.is_icon"
                        :to="item.icon.url"
                      >
                        <v-avatar>
                          <img
                            :src="item.icon.src"
                            :title="item.icon.title"
                            :alt="item.icon.title"
                          />
                        </v-avatar>
                      </router-link>
                      <router-link
                        slot="icon"
                        v-if="item.icon.is_icon"
                        :to="item.icon.url"
                      >
                        <v-icon
                          :color="item.icon.color"
                          small
                          class="timeline-icon"
                          >{{ item.icon.src }}</v-icon
                        >
                      </router-link>

                      <v-card
                        :color="item.color"
                        class="timeline-item-content"
                        outlined
                      >
                        <v-card-title class="pt-1 pb-1 timeline-item-header">
                          <div class="timeline-time subtitle-1">
                            {{ item.date }}
                          </div>

                          <button-menu
                            icon
                            dark
                            absolute
                            right
                            :items="getItemMenu(item)"
                            btn-icon="$vuetify.icons.dotsHorizontal"
                            btn-class="mt-n4"
                            v-if="user"
                            :is-loading="item.deleting || item.is_abusing"
                          ></button-menu>
                        </v-card-title>

                        <blog-post
                          v-if="item.event_type == $enums.FEEDEVENTS.BLOGPOST"
                          :item="item"
                        ></blog-post>
                        <media-album
                          v-if="item.event_type == $enums.FEEDEVENTS.MEDIAALBUM"
                          :item="item"
                        ></media-album>
                        <child-event
                          v-if="item.event_type == $enums.FEEDEVENTS.CHILDEVENT"
                          :item="item"
                        ></child-event>
                        <child-size
                          v-if="item.event_type == $enums.FEEDEVENTS.CHILDSIZE"
                          :item="item"
                        ></child-size>
                        <own-event
                          v-if="item.event_type == $enums.FEEDEVENTS.OWNEVENT"
                          :item="item"
                        ></own-event>
                        <child-birthday
                          v-if="
                            item.event_type == $enums.FEEDEVENTS.CHILDBIRTHDAYS
                          "
                          :item="item"
                        ></child-birthday>
                        <child-memory
                          v-if="
                            item.event_type == $enums.FEEDEVENTS.CHILDMEMORY
                          "
                          :item="item"
                        ></child-memory>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>

                  <div v-if="has_more" class="text-xs-center pt-10 pb-10">
                    <v-btn
                      block
                      text
                      color="primary"
                      :loading="is_loading"
                      @click="loadMore"
                      >{{ $t("LoadMore") }}</v-btn
                    >
                  </div>

                  <div
                    class="text-center pt-10"
                    v-if="!items || items.length < 1"
                  >
                    {{ $t("EmptyTimeline") }}
                  </div>

                  <settings-dialog
                    v-if="is_admin"
                    v-on:updated="onSettingsUpdated"
                    ref="timelineSettingsDialog"
                  ></settings-dialog>
                </template>
                <error-page :error="error" v-if="error"></error-page>
              </div>
            </v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Delete": "Delete post",
    "Abuse": "Abuse post",
    "LoadMore": "Load more",
    "Timeline": "Timeline",
    "SuccessAbuse": "Abuse sent!",
    "SuccessDeletePost": "Post deleted!",
    "EmptyTimeline": "There is no posts to show!",
    "ConfirmDeletePost": "Are you sure you want to delete this post?",
    "ConfirmAbusePost": "Are you sure you want to abuse this post?",
    "TimelineSettings": "Timeline settings"
    },
    "sv": {
    "Delete": "Ta bort inlägg",
    "Abuse": "Anmäl inlägg",
    "LoadMore": "Visa mer",
    "Timeline": "Tidslinje",
    "SuccessAbuse": "Anmälan skickades!",
    "SuccessDeletePost": "Posten togs bort!",
    "ConfirmDeletePost": "Är du säker på att du vill ta bort denna post?",
    "EmptyTimeline": "Det finns inga inlägg i tidslinjen att visa ännu!",
    "ConfirmAbusePost": "Är du säker på att du vill anmäla denna post till granskning?",
    "TimelineSettings": "Inställningar för tidslinjen"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

import BlogPost from "@/components/diary/helpers/TimelineItems/BlogPost.vue";
import ChildBirthday from "@/components/diary/helpers/TimelineItems/ChildBirthday.vue";
import ChildEvent from "@/components/diary/helpers/TimelineItems/ChildEvent.vue";
import ChildMemory from "@/components/diary/helpers/TimelineItems/ChildMemory.vue";
import ChildSize from "@/components/diary/helpers/TimelineItems/ChildSize.vue";
import MediaAlbum from "@/components/diary/helpers/TimelineItems/MediaAlbum.vue";
import OwnEvent from "@/components/diary/helpers/TimelineItems/OwnEvent.vue";
import Profile from "@/components/diary/widgets/Profile.vue";
import Children from "@/components/diary/widgets/Children.vue";

const TimelineSettingsDialog = () =>
  import("@/components/diary/dialogs/TimelineSettingsDialog.vue");

export default {
  name: "diary_home",
  metaInfo() {
    return {
      title: "",
      titleTemplate: this.diary.title + " | " + process.env.VUE_APP_NAME,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,

      page: (state) => state.diary.timeline.page,
      items: (state) => state.diary.timeline.list,
      error: (state) => state.diary.timeline.error,
      has_more: (state) => state.diary.timeline.has_more,
      init_load: (state) => state.diary.timeline.init_load,
      is_loading: (state) => state.diary.timeline.is_loading,
    }),
    admin_menu: function () {
      var data = [];

      data.push({
        name: this.$t("TimelineSettings"),
        icon: "$vuetify.icons.fasCog",
        click: this.openTimelineSettings,
      });

      return data;
    },
  },
  components: {
    "blog-post": BlogPost,
    "child-event": ChildEvent,
    "child-size": ChildSize,
    "media-album": MediaAlbum,
    "own-event": OwnEvent,
    "diary-profile": Profile,
    "widget-children": Children,
    "child-birthday": ChildBirthday,
    "settings-dialog": TimelineSettingsDialog,
    "child-memory": ChildMemory,
  },
  data: () => ({}),
  created: function () {
    this.load();
  },
  methods: {
    ...mapActions({
      loadPosts: "diary/timeline/load",
      reset: "diary/timeline/reset",
      delete: "diary/timeline/delete",
      abuse: "diary/timeline/abuse",
    }),

    getItemMenu(item) {
      var data = [];
      var self = this;

      if (this.user && !this.is_admin) {
        data.push({
          name: this.$t("Abuse"),
          icon: "$vuetify.icons.alertCircleOutline",
          click: function () {
            self.abuseItem(item);
          },
        });
      }

      if (this.is_admin) {
        data.push({
          name: this.$t("Delete"),
          icon: "$vuetify.icons.trashCanOutline",
          click: function () {
            self.deleteItem(item);
          },
        });
      }

      return data;
    },

    load() {
      if (this.page == 0) {
        this.loadPosts();
      }
    },
    loadMore() {
      this.loadPosts();
    },
    reload() {
      this.reset();
      this.load();
    },
    openTimelineSettings() {
      this.$refs.timelineSettingsDialog.open();
    },
    onSettingsUpdated() {
      this.reload();
    },
    async deleteItem(item) {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeletePost"));

      if (res) {
        self.delete(item.id).then(function () {
          self.$successNoty(self.$t("SuccessDeletePost"));
        });
      }
    },
    async abuseItem(item) {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmAbusePost"));

      if (res) {
        self.abuse(item.id).then(function () {
          self.$successNoty(self.$t("SuccessAbuse"));
        });
      }
    },
  },
};
</script>

<style scoped>
.timeline-time .caption a {
  color: white;
  text-decoration: none;
}
.timeline-time .caption a:hover {
  text-decoration: underline;
}
</style>