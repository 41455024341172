<template>
  <v-container class="guestbook-page diary-page">
    <v-card min-height="300" elevation="1">
      <v-card-title>
        <h2 class="text-h6">{{ $t("Guestbook") }}</h2>

        <v-spacer></v-spacer>
        <v-btn
          v-if="is_admin"
          text
          exact
          color="primary"
          :to="'/' + diary.name + '/guestbook'"
          class="text-none text-subtitle-1"
        >
          {{ $t("BackToGuestbook") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader
          :loading="is_loading"
          type="list-item-avatar-three-line"
        >
          <div>
            <div v-if="!error">
              <v-col
                cols="12"
                v-if="!comment || !comment.id"
                class="text-center"
                >{{ $t("NoComments") }}</v-col
              >

              <v-timeline
                align-top
                dense
                v-if="comment && comment.id"
                class="ml-n3"
              >
                <comment-item
                  :data-service="dataService"
                  v-on:deleted="onDeleted"
                  :comment="comment"
                  :key="comment.id"
                ></comment-item>
              </v-timeline>
            </div>
            <error-box :error="error"></error-box>
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "NoComments": "Comment not found!",
    "Guestbook": "Guestbook post",
    "BackToGuestbook": "Back to guestbook"
    },
    "sv": {
    "NoComments": "Kommentaren kunde inte hittas!",
    "Guestbook": "Gästboksinlägg",
    "BackToGuestbook": "Tillbaka till gästboken"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { guestbookService } from "@/_services";
import CommentItem from "@/components/diary/comments/CommentItem.vue";

export default {
  name: "diary_guestbook_post",
  metaInfo() {
    return this.meta;
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "comment-item": CommentItem,
  },
  data: () => ({
    dataService: guestbookService,

    error: null,
    is_loading: false,
    comment: {},

    meta: {},
  }),
  created: function () {
    var id = this.$route.params.id;

    this.loadComment(id);
    this.setMeta();
  },
  methods: {
    loadComment(id) {
      var self = this;
      self.error = null;
      self.is_loading = true;

      guestbookService
        .getPost(id)
        .then(function (response) {
          self.comment = self.generateComment(response.data);
          self.is_loading = false;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    generateComment(comment) {
      if (comment.parent && comment.parent.id) {
        var parent = { ...comment.parent };
        var child = { ...comment };

        delete child.answers;
        delete child.parent;
        delete parent.answers;
        delete parent.parent;

        parent.answers = [];
        parent.answers.push(child);

        return parent;
      }

      return comment;
    },
    onDeleted() {
      this.$router.push("/" + this.diary.name + "/guestbook");
    },
    setMeta() {
      this.meta = {
        titleTemplate:
          this.$t("Guestbook") +
          " - " +
          this.diary.title +
          " | " +
          process.env.VUE_APP_NAME,
      };
    },
  },
};
</script>