<template>
  <v-container class="blog-page diary-page">
    <v-row class="mobile-margin-fix mt-0">
      <v-col xs="12" md="12" cols="12" class="diary-main mobile-padding-fix">
        <v-skeleton-loader :loading="is_loading" type="article">
          <div>
            <v-card class="mb-6" elevation="1">
              <v-card-title>
                {{ $t("Posts") }}

                <v-spacer></v-spacer>

                <button-menu
                  depressed
                  :items="menu_categories"
                  btn-icon="$vuetify.icons.faFilter"
                  :btn-text="$t('Filter')"
                  btn-icon-small
                  menu-class="filter-menu"
                  :subheader-text="$t('Category')"
                ></button-menu>

                <template v-if="is_admin">
                  <v-btn
                    :to="{ name: 'diary_blog_new' }"
                    class="text-none ml-2"
                    depressed
                    color="primary"
                    v-if="!$isMobile"
                  >
                    <v-icon left>$vuetify.icons.fasPlus</v-icon>
                    {{ $t("CreatePost") }}</v-btn
                  >

                  <button-menu
                    depressed
                    class="ml-2"
                    :items="settings_menu"
                    btn-icon="$vuetify.icons.dotsHorizontal"
                  ></button-menu>

                  <settings-dialog ref="settingsDialog"></settings-dialog
                ></template>
              </v-card-title>
            </v-card>

            <template v-if="!error">
              <v-card v-if="!news || news.length < 1" elevation="1">
                <v-card-text class="text-center pt-14 pb-14">
                  {{ $t("NoPosts") }}
                </v-card-text>
              </v-card>

              <template v-if="news && news.length > 0">
                <news-list
                  :news="news"
                  v-on:deleted="onDeleted"
                  v-if="listType == 0"
                ></news-list>
                <news-cards
                  :news="news"
                  v-on:deleted="onDeleted"
                  v-if="listType == 1"
                ></news-cards>

                <v-col
                  xs="12"
                  cols="12"
                  class="text-center mt-4 mb-10"
                  v-if="unit_pages > 1"
                >
                  <v-pagination
                    v-model="page"
                    :length="unit_pages"
                    :total-visible="7"
                  ></v-pagination>
                </v-col>
              </template>
            </template>
            <error-page :error="error" v-if="error"></error-page>
          </div>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "NoPosts": "No post to show!",
    "Diary": "Diary",
    "CreatePost": "Create post",
    "Posts": "Posts",
    "Filter": "Filter",
    "Category": "Category",
    "NoCategories": "No categories",
    "ManagePosts": "Manage posts",
    "Settings": "Settings"
    },
    "sv": {
    "NoPosts": "Det finns inga inlägg att visa ännu!",
    "Diary": "Dagbok",
    "CreatePost": "Skapa inlägg",
    "Posts": "Inlägg",
    "Filter": "Filter",
    "Category": "Kategori",
    "NoCategories": "Inga kategorier",
    "ManagePosts": "Hantera inlägg",
    "Settings": "Inställningar"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import ListType from "@/components/diary/helpers/BlogPostList/List.vue";
import CardType from "@/components/diary/helpers/BlogPostList/Cards.vue";
const BlogPostSettingsDialog = () =>
  import("@/components/diary/dialogs/BlogPostSettingsDialog.vue");
import { dateHelpers } from "@/_helpers";

export default {
  name: "diary_news",
  metaInfo() {
    return {
      titleTemplate:
        this.$t("Diary") +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content: this.$t("Diary") + " | " + this.diary.title,
          vmid: "og:title",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      settings: (state) => state.diary.settings,
      is_admin: (state) => state.diary.is_admin,

      unit_pages: (state) => state.diary.news.unit_pages,
      loaded_page: (state) => state.diary.news.current_page,
      current_category: (state) => state.diary.news.current_category,
      is_loading: (state) => state.diary.news.is_loading,
      error: (state) => state.diary.news.error,
      news: (state) => state.diary.news.list,
      categories: (state) => state.diary.categories.list,
    }),
    listType: function () {
      if (
        !this.settings ||
        !this.settings.BlogPageSettings ||
        !this.settings.BlogPageSettings.list_type
      )
        return 0;
      return this.settings.BlogPageSettings.list_type;
    },
    menu_categories: function () {
      var data = [];

      this.categories.forEach((item) => {
        data.push({
          to: {
            name: "diary_blog_category",
            params: { category: item.url_name },
          },
          name: item.name,
        });
      });

      return data;
    },
  },

  components: {
    "news-list": ListType,
    "news-cards": CardType,
    "settings-dialog": BlogPostSettingsDialog,
  },
  data: () => ({
    page: 1,
    settings_menu: [],
  }),
  created: function () {
    this.setFromUrl();
    this.loadNews();

    if (this.is_admin) this.settingsMenu();
  },
  methods: {
    ...mapActions({
      load: "diary/news/load",
      loadInCategory: "diary/news/loadInCategory",
    }),

    settingsMenu() {
      if (this.$isMobile) {
        this.settings_menu.push({
          name: this.$t("CreatePost"),
          icon: "$vuetify.icons.fasPlus",
          to: { name: "diary_blog_new" },
        });
      }

      this.settings_menu.push({
        name: this.$t("ManagePosts"),
        icon: "$vuetify.icons.farEdit",
        to: { name: "diary_admin_posts" },
      });

      this.settings_menu.push({
        name: this.$t("Settings"),
        icon: "$vuetify.icons.fasCog",
        click: this.openSettings,
      });
    },

    loadNews() {
      var self = this;

      var category = this.$route.params.category;

      if (category) {
        if (category == self.current_category && self.page == self.loaded_page)
          return;

        self.loadInCategory({ category, page: self.page });
      } else {
        if (self.page == self.loaded_page && self.current_category == category)
          return;
        self.load(self.page);
      }
    },
    setFromUrl() {
      this.page = 1;

      if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    },
    onDeleted() {
      this.loadNews();
    },
    getDate(date) {
      var dt = dateHelpers.getFormatedDateFromDatetime(date);
      return dateHelpers.getFormatedTimelineDate(dt);
    },
    openSettings() {
      this.$refs.settingsDialog.open();
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadNews();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.page) return;
      self.$router.push({ query: { page: self.page } });
    },
  },
};
</script>


<style>
.filter-menu.button-menu-card {
  width: 200px !important;
}
.filter-menu.button-menu-card .button-menu-card-container {
  max-height: 400px;
  overflow-y: auto;
}
</style>