<template>
  <v-container class="child-profile-page diary-page">
    <v-skeleton-loader :loading="is_loading" type="article">
      <template>
        <v-row
          class="diary-master-main mt-0 mobile-margin-fix"
          v-if="!error && child"
        >
          <v-col xs="12" md="3" cols="12" class="mobile-padding-fix">
            <v-card class="mb-6" elevation="1">
              <v-card-text class="text-center pb-0">
                <div>
                  <child-avatar
                    :child="child"
                    :size="150"
                    v-on:clicked="current_image = 0"
                    v-bind:class="{ pointer: child.avatar }"
                  ></child-avatar>
                  <v-btn
                    fab
                    v-if="is_admin"
                    class="ml-n8"
                    small
                    bottom
                    @click="editAvatar"
                    elevation="1"
                    style="margin-top: 75px"
                  >
                    <v-icon small>$vuetify.icons.fasPen</v-icon>
                  </v-btn>
                </div>

                <avatar-dialog
                  ref="avatarDialog"
                  show-album
                  v-if="is_admin"
                  v-on:uploaded="onAvatarUpdated"
                  v-on:deleted="onAvatarDeleted"
                ></avatar-dialog>

                <Tinybox
                  v-if="child.avatar"
                  no-thumbs
                  :images="getAvatars(child.avatar)"
                  v-model="current_image"
                />

                <h2 class="title mt-2 text--primary">
                  {{ child.name | capitalize }}
                </h2>
                <div class="body-2">
                  {{ getFormatedChildAge(child.birth) }}
                </div>
              </v-card-text>
              <child-about></child-about>
            </v-card>

            <child-memories></child-memories>
          </v-col>
          <v-col xs="12" md="9" cols="12" class="diary-main mobile-padding-fix">
            <v-card style="min-height: 500px" elevation="1">
              <v-toolbar flat>
                <v-toolbar-title>{{ child.name | capitalize }}</v-toolbar-title>

                <v-spacer></v-spacer>

                <button-menu
                  depressed
                  :items="admin_menu"
                  btn-icon="$vuetify.icons.dotsHorizontal"
                  v-if="is_admin"
                ></button-menu>

                <template v-slot:extension>
                  <v-tabs
                    class="mobile-tabs-fix tab-border-bottom-fix"
                    active-class="active-menu"
                    grow
                    hide-slider
                  >
                    <v-tab :to="getMenuUrl('')" exact-path class="text-none">{{
                      $t("Timeline")
                    }}</v-tab>
                    <v-tab :to="getMenuUrl('/diary')" class="text-none">{{
                      $t("Diary")
                    }}</v-tab>
                    <v-tab :to="getMenuUrl('/photos')" class="text-none">{{
                      $t("Photos")
                    }}</v-tab>
                    <v-tab :to="getMenuUrl('/memories')" class="text-none">{{
                      $t("Memories")
                    }}</v-tab>
                    <v-tab :to="getMenuUrl('/growth')" class="text-none">{{
                      $t("Growth")
                    }}</v-tab>
                    <v-tab :to="getMenuUrl('/progress')" class="text-none">{{
                      $t("Progress")
                    }}</v-tab>
                  </v-tabs>
                </template>
              </v-toolbar>
              <router-view :child="child" :key="$route.fullPath"></router-view>
            </v-card>
          </v-col>
          <new-dialog
            v-on:closed="onWelcomeDone"
            v-if="is_admin && show_welcome"
            :child="child"
          ></new-dialog>
          <edit-dialog
            v-on:updated="onUpdated"
            v-if="is_admin"
            ref="editChildDialog"
          ></edit-dialog>
        </v-row>
        <v-card v-if="error">
          <error-page :error="error" :home="'/' + diary.name"></error-page>
        </v-card>
      </template>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Timeline": "Timeline",
    "Growth": "Growth",
    "Progress": "Progress",
    "ChildNotFound": "Child not found!",
    "Manage": "Manage",
    "EditInformation": "Edit information",
    "EditAvatar": "Edit avatar",
    "DeleteChild": "Delete child",
    "Diary": "Diary",
    "Photos": "Photos",
    "Child": "Child",
    "Memories": "Memories"
    },
    "sv": {
    "Timeline": "Tidslinje",
    "Growth": "Tillväxt",
    "Progress": "Framsteg",
    "ChildNotFound": "Barnet kunde inte hittas!",
    "Manage": "Administrera",
    "EditInformation": "Redigera profil",
    "EditAvatar": "Redigera profilbild",
    "DeleteChild": "Ta bort barn",
    "Diary": "Dagbok",
    "Photos": "Bilder",
    "Child": "Barn",
    "Memories": "Minnen"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import About from "@/components/diary/childprofile/About.vue";
import Memories from "@/components/diary/childprofile/Memories.vue";
import Tinybox from "vue-tinybox";
import ChildAvatar from "@/components/avatars/ChildAvatar.vue";
import { dateHelpers } from "@/_helpers";

export default {
  name: "child_profile_master",

  components: {
    "child-about": About,
    "child-memories": Memories,
    "avatar-dialog": () => import("@/components/dialogs/AvatarDialog.vue"),
    "edit-dialog": () =>
      import("@/components/diary/dialogs/ChildFormDialog.vue"),
    "new-dialog": () =>
      import("@/components/diary/dialogs/WelcomeNewChildDialog.vue"),
    "child-avatar": ChildAvatar,
    Tinybox,
  },
  metaInfo() {
    return {
      title: this.child.name,
      meta: [
        {
          property: "og:title",
          content: this.child.name + " - " + this.diary.title,
          vmid: "og:title",
        },
        {
          name: "description",
          content: this.child.description,
          vmid: "description",
        },
        {
          property: "og:description",
          content: this.child.description,
          vmid: "og:description",
        },
        {
          property: "og:image",
          content: this.$imagehelpers.childAvatar(this.child.avatar, 300),
          vmid: "og:image",
        },
        { property: "og:image:width", content: "300", vmid: "og:image:width" },
        {
          property: "og:image:height",
          content: "300",
          vmid: "og:image:height",
        },
        {
          property: "twitter:image:src",
          content: this.$imagehelpers.childAvatar(this.child.avatar, 300),
          vmid: "twitter:image:src",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      children: (state) => state.diary.children.list,
      is_admin: (state) => state.diary.is_admin,

      is_loading: (state) => state.diary.childprofile.is_loading,
      error: (state) => state.diary.childprofile.error,
      child: (state) => state.diary.childprofile.child,
    }),
    admin_menu: function () {
      var data = [];

      data.push({
        name: this.$t("EditInformation"),
        icon: "$vuetify.icons.acountEditOutline",
        click: this.openEditDialog,
      });

      data.push({
        name: this.$t("DeleteChild"),
        icon: "$vuetify.icons.trashCanOutline",
        to: { name: "diary_child_delete" },
      });

      return data;
    },
  },
  data: () => ({
    current_image: null,
    show_welcome: false,
  }),
  created: function () {
    this.load();
  },
  methods: {
    ...mapActions({
      loadChild: "diary/childprofile/load",
    }),
    getFormatedChildAge: dateHelpers.getFormatedChildAge,
    load() {
      this.loadChild(this.$route.params.name);
    },
    getMenuUrl(path) {
      return "/" + this.diary.name + "/child/" + this.child.url_name + path;
    },
    editAvatar() {
      this.$refs.avatarDialog.openChildAvatar(this.diary, this.child);
    },
    onAvatarUpdated(avatar) {
      this.$store.commit(
        "diary/childprofile/updateChild",
        { avatar: avatar },
        { root: true }
      );
    },
    onAvatarDeleted() {
      this.$store.commit(
        "diary/childprofile/updateChild",
        { avatar: null },
        { root: true }
      );
    },
    onUpdated(child) {
      this.$store.commit("diary/childprofile/updateChild", child, {
        root: true,
      });
    },
    openEditDialog() {
      this.$refs.editChildDialog.editChild(this.child);
    },
    getAvatars(image) {
      return [image.original];
    },
    onWelcomeDone() {
      //this.$router.push("/" + this.diary.name + "/child/" + this.child.url_name);
      this.show_welcome = false;
    },
  },
  watch: {
    /*children: function () {
      this.child = this.$store.getters["diary/children/getChildByUrlName"](
        this.$route.params.name
      );
    },*/
    "$route.params.name": {
      handler: function (current, old) {
        if (current != old && old) {
          this.load();
        }
      },
      deep: true,
      immediate: true,
    },
    "$route.query.is_new": {
      handler: function (current, old) {
        if (this.is_admin && current != old && current == "true") {
          this.$store.commit("diary/childprofile/welcomeStatus", true, {
            root: true,
          });
          this.$router.replace({ query: null });
          this.show_welcome = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>