<template>
  <v-container class="diary-search-page diary-page">
    <v-skeleton-loader :loading="search.is_loading" type="article">
      <div>
        <template v-if="!search.error">
          <v-row>
            <v-col cols="12" xs="12" md="3">
              <v-card elevation="1">
                <v-card-title>{{ $t("FilterResult") }}</v-card-title>

                <v-list class="pt-0 admin-list-menu">
                  <v-list-item-group v-model="menu" color="primary">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>$vuetify.icons.earth</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t("All") }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>$vuetify.icons.newspaperVariantOutline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("DiaryPosts")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>$vuetify.icons.baby</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("Children")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>$vuetify.icons.imageAlbum</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("Albums")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>$vuetify.icons.image</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t("Media") }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col cols="12" xs="12" md="9">
              <v-card class="mb-6" v-if="menu == 0 || menu == 1">
                <v-card-title>{{ $t("DiaryPosts") }}</v-card-title>
                <v-card-text
                  v-if="
                    search.result.blogposts &&
                    search.result.blogposts.length > 0
                  "
                >
                  <v-row>
                    <v-col
                      cols="12"
                      xs="12"
                      md="4"
                      class="pt-0"
                      v-for="item in search.result.blogposts"
                      :key="item.id"
                    >
                      <v-hover v-slot:default="{ hover }">
                        <v-card
                          :elevation="hover ? 2 : 0"
                          :to="getPostUrl(item)"
                          outlined
                          class="mb-4"
                        >
                          <v-card-title class="news-title">
                            <h2 class="headline">
                              {{ item.header | capitalize }}
                            </h2>
                          </v-card-title>

                          <v-card-subtitle class="caption">
                            {{ $t("Published") }} {{ getDate(item.show_date) }}
                            <privacy-icon
                              :privacy="item.privacy"
                            ></privacy-icon>
                          </v-card-subtitle>

                          <v-card-text class="text--primary">
                            <div class="news-preamble">{{ item.preamble }}</div>
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else class="text-center">{{
                  $t("NoDiaryPosts")
                }}</v-card-text>
              </v-card>

              <v-card class="mb-6" v-if="menu == 0 || menu == 2">
                <v-card-title>{{ $t("Children") }}</v-card-title>
                <v-card-text
                  v-if="
                    search.result.children && search.result.children.length > 0
                  "
                >
                  <v-row>
                    <v-col
                      cols="12"
                      xs="12"
                      md="4"
                      class="pt-0"
                      v-for="item in search.result.children"
                      :key="item.id"
                    >
                      <v-hover v-slot:default="{ hover }">
                        <v-card
                          :elevation="hover ? 2 : 0"
                          outlined
                          :to="getChildUrl(item.url_name)"
                          class="mb-4"
                        >
                          <v-img
                            :src="$imagehelpers.childAvatar(item.avatar, 300)"
                            height="150"
                            :alt="item.name"
                            :title="item.name"
                            class="grey lighten-2"
                            mx-auto
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-card-title class="news-title mx-auto">
                            <h2 class="headline mx-auto">
                              {{ item.name | capitalize }}
                            </h2>
                          </v-card-title>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else class="text-center">{{
                  $t("NoChildren")
                }}</v-card-text>
              </v-card>

              <v-card class="mb-6" v-if="menu == 0 || menu == 3">
                <v-card-title>{{ $t("Albums") }}</v-card-title>
                <v-card-text
                  v-if="search.result.albums && search.result.albums.length > 0"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      xs="12"
                      md="4"
                      class="pt-0"
                      v-for="item in search.result.albums"
                      :key="item.id"
                    >
                      <v-hover v-slot:default="{ hover }">
                        <v-card
                          :elevation="hover ? 2 : 0"
                          :to="getAlbumUrl(item.url_name)"
                          outlined
                          class="mb-4"
                        >
                          <v-img
                            :src="$imagehelpers.mediaWidth(item.image, 400)"
                            height="150"
                            class="grey lighten-2"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-card-title class="news-title mx-auto">
                            <h2 class="headline mx-auto">
                              {{ item.title | capitalize }}
                            </h2>
                          </v-card-title>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else class="text-center">{{
                  $t("NoAlbums")
                }}</v-card-text>
              </v-card>

              <v-card v-if="menu == 0 || menu == 4">
                <v-card-title>{{ $t("Media") }}</v-card-title>
                <v-card-text
                  v-if="search.result.media && search.result.media.length > 0"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      xs="12"
                      md="4"
                      class="pt-0"
                      v-for="item in search.result.media"
                      :key="item.id"
                    >
                      <v-hover v-slot:default="{ hover }">
                        <v-card
                          :elevation="hover ? 2 : 0"
                          :to="'/' + diary.name + '/album/media/' + item.id"
                          outlined
                          class="mb-4"
                        >
                          <v-img
                            :src="$imagehelpers.mediaWidth(item.image, 400)"
                            height="150"
                            class="grey lighten-2"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-card-title class="news-title mx-auto">
                            <h2 class="headline mx-auto">
                              {{ item.title | capitalize }}
                            </h2>
                          </v-card-title>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else class="text-center">{{
                  $t("NoMedia")
                }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <template v-if="search.error">
          <v-card outlined>
            <error-page :error="search.error" v-if="error"></error-page>
          </v-card>
        </template>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "FilterResult": "Filter",
    "All": "All",
    "Children": "Children",
    "DiaryPosts": "Diary posts",
    "Albums": "Photoalbums",
    "Media": "Photos",
    "NoMedia": "There is no photos that match the search!",
    "NoDiaryPosts": "No post match your search!",
    "NoChildren": "No children match your search!",
    "NoAlbums": "No albums match your search!",
    "Search": "Search",
    "Published": "Published"
    },
    "sv": {
    "FilterResult": "Filtrera",
    "All": "Alla",
    "Children": "Barn",
    "DiaryPosts": "Dagboksinlägg",
    "Albums": "Bildalbum",
    "Media": "Bilder",
    "NoMedia": "Det finns inga bilder i dagboken som matchar sökningen!",
    "NoDiaryPosts": "Det finns inga inlägg i dagboken som matchar din sökning!",
    "NoChildren": "Inga barn i dagboken matchade din sökning!",
    "NoAlbums": "Det finns inga album i dagboken som matchar din sökning!",
    "Search": "Sök",
    "Published": "Publicerad"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import PrivacyIcon from "@/components/global/Privacy.vue";
import { dateHelpers } from "@/_helpers";

export default {
  name: "diary_search",
  metaInfo() {
    return {
      titleTemplate:
        this.$t("Search") +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content: this.$t("Search") + " | " + this.diary.title,
          vmid: "og:title",
        },
      ],
    };
  },
  components: {
    "privacy-icon": PrivacyIcon,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      search: (state) => state.diary.search,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    menu: 0,
  }),
  created: function () {
    var searchQ = this.$route.query.q;
    if (searchQ) this.searchData(searchQ);
  },
  methods: {
    ...mapActions({
      searchInDiary: "diary/search/searchInDiary",
    }),
    searchData(q) {
      this.searchInDiary(q);
    },
    getChildUrl(urlName) {
      return "/" + this.diary.name + "/child/" + urlName;
    },
    getAlbumUrl(urlName) {
      return "/" + this.diary.name + "/album/" + urlName;
    },
    getPostUrl(item) {
      return "/" + this.diary.name + "/diary/" + item.url_name;
    },
    getDate(date) {
      var dt = dateHelpers.getFormatedDateFromDatetime(date);
      return dateHelpers.getFormatedTimelineDate(dt);
    },
  },
};
</script>


<style scoped>
.news-title h2 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 20px !important;
  line-height: 27px !important;
}
.news-preamble {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>