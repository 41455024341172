<template>
  <v-container class="news-item-page diary-page">
    <v-skeleton-loader :loading="is_loading" type="article">
      <v-row class="mobile-margin-fix mt-0">
        <v-col xs="12" md="12" cols="12" class="diary-main mobile-padding-fix">
          <v-card class="pb-15" min-height="300" elevation="1">
            <template v-if="!error">
              <post-cover
                :post="post"
                v-if="post.cover && post.cover.length > 0"
              ></post-cover>
              <v-card-title>
                <h2 class="headline">{{ post.header | capitalize }}</h2>
                <post-menu
                  v-if="user"
                  v-on:deleted="onDeleted"
                  :post="post"
                ></post-menu>
              </v-card-title>
              <v-card-subtitle class="caption">
                {{ $t("Published") }} {{ getDate(post.show_date) }}
                <privacy-icon :privacy="post.privacy"></privacy-icon>
              </v-card-subtitle>
              <v-card-text
                class="text--primary image-width-fix"
                v-html="post.text"
              ></v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="pr-4 pt-3 pb-3">
                <v-spacer></v-spacer>
                <my-share
                  v-if="
                    (post.settings && !post.settings.hide_share) ||
                    !post.settings
                  "
                  :url="getUrl(post)"
                  :title="post.header"
                  display-type="all"
                ></my-share>
              </v-card-actions>
              <v-divider></v-divider>

              <div id="comments">
                <v-col cols="12" v-if="show_comments">
                  <template v-if="post.allow_comments">
                    <h3>{{ $t("Comments") }}</h3>
                    <post-comments
                      v-if="!comment_id"
                      :post="post"
                    ></post-comments>
                    <post-comment
                      v-if="comment_id"
                      :post="post"
                      :id="comment_id"
                    ></post-comment>
                  </template>
                  <v-col
                    cols="12"
                    v-if="!post.allow_comments"
                    class="mt-6 pb-6 text-center body-2"
                    >{{ $t("CommentsInactivated") }}</v-col
                  >
                </v-col>
              </div>
            </template>
            <error-page :error="error" v-if="error"></error-page>
          </v-card>
        </v-col>
      </v-row>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Comments": "Comments",
    "CommentsInactivated": "Comments have been disabled for this post!",
    "Diary": "Diary",
    "Published": "Published"
    },
    "sv": {
    "Comments": "Kommentarer",
    "CommentsInactivated": "Kommentarer har inaktiverats för detta inlägg!",
    "Diary": "Dagbok",
    "Published": "Publicerad"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { blogPostService } from "../../../_services";
import Comments from "../../../components/diary/comments/BlogPostComments.vue";
import CommentItem from "../../../components/diary/comments/BlogPostCommentItem.vue";
import BlogPostMenu from "../../../components/diary/helpers/BlogPostMenu.vue";
import PrivacyIcon from "../../../components/global/Privacy.vue";
import BlogPostCover from "../../../components/diary/helpers/BlogPostCover.vue";
import Share from "@/components/global/Share.vue";
import { dateHelpers } from "@/_helpers";

export default {
  name: "diary_news_item",
  metaInfo() {
    return {
      titleTemplate:
        this.post.header +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content:
            this.post.header +
            " - " +
            this.$t("Diary") +
            " - " +
            this.diary.title,
          vmid: "og:title",
        },
        {
          name: "description",
          content: this.post.preamble,
          vmid: "description",
        },
        {
          property: "og:description",
          content: this.post.preamble,
          vmid: "og:description",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "post-comments": Comments,
    "post-menu": BlogPostMenu,
    "post-comment": CommentItem,
    "privacy-icon": PrivacyIcon,
    "post-cover": BlogPostCover,
    "my-share": Share,
  },
  data: () => ({
    post: {},
    is_loading: false,
    error: null,
    show_comments: false,

    comment_id: null,
  }),
  created: function () {
    this.loadNews();
  },
  methods: {
    loadNews() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      var urlName = self.$route.params.name;
      var temp = self.$store.getters["diary/news/getNewsByUrlName"](urlName);
      this.comment_id = self.$route.params.id;

      if (temp) {
        self.post = temp;
        self.is_loading = false;
        self.show_comments = true;
      } else {
        blogPostService
          .getPostByName(self.diary.name, urlName)
          .then(function (response) {
            self.post = response.data;
            self.is_loading = false;
            self.show_comments = true;
          })
          .catch(function (error) {
            self.error = error;
            self.is_loading = false;
          });
      }
    },
    onDeleted() {
      this.$router.push("/" + this.diary.name + "/diary");
    },
    getUrl(item) {
      return "/" + this.diary.name + "/diary/" + item.url_name;
    },
    getDate(date) {
      var dt = dateHelpers.getFormatedDateFromDatetime(date);
      return dateHelpers.getFormatedTimelineDate(dt);
    },
  },
  watch: {
    $route() {
      this.comment_id = this.$route.params.id;
    },
  },
};
</script>