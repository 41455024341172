<template>
  <v-container class="guestbook-page diary-page">
    <v-card min-height="300" class="pb-15" elevation="1">
      <v-card-title>
        <h2 class="text-h6" style="margin-right: 70px">
          {{ $t("Guestbook") }}
        </h2>

        <v-spacer></v-spacer>

        <button-menu
          depressed
          :items="[
            {
              name: $t('ManageComments'),
              to: {
                name: 'diary_admin_comments_status',
                params: { status: 'pending' },
                query: { type: 'guestbook' },
              },
            },
          ]"
          btn-icon="$vuetify.icons.dotsHorizontal"
          v-if="is_admin"
        ></button-menu>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader
          :loading="is_loading"
          type="list-item-avatar-three-line"
        >
          <div>
            <div v-if="!error">
              <v-timeline
                align-top
                dense
                class="ml-n7"
                v-if="user || comments.length > 0"
              >
                <v-timeline-item right v-if="user">
                  <template v-slot:icon>
                    <user-avatar :user="user" :size="50"></user-avatar>
                  </template>
                  <v-card outlined>
                    <v-form ref="create_form" v-model="create_form">
                      <v-card-text class="pa-0">
                        <v-textarea
                          auto-grow
                          flat
                          counter="700"
                          solo
                          :rules="[(v) => !!v || '']"
                          required
                          :loading="is_creating"
                          :disabled="is_creating"
                          v-model="comment.text"
                          :label="$t('LeaveComment')"
                        ></v-textarea>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="createComment"
                          :loading="is_creating"
                          color="primary"
                          class="text-none"
                          >{{ $t("Button.Publish") }}</v-btn
                        >
                      </v-card-actions>
                      <error-box :error="create_error"></error-box>
                    </v-form>
                  </v-card>
                </v-timeline-item>
                <comment-item
                  :data-service="dataService"
                  v-on:deleted="loadComments"
                  v-on:answer="loadComments"
                  v-on:userBlocked="onUserBlocked"
                  v-on:userUnblocked="onUserUnblocked"
                  v-on:answerDeleted="onAnswerDeleted"
                  :comment="item"
                  v-for="item in comments"
                  :key="item.id"
                ></comment-item>
              </v-timeline>

              <v-col
                cols="12"
                v-if="comments.length < 1"
                class="text-center pt-10"
                >{{ $t("NoComments") }}</v-col
              >

              <v-col cols="12" class="text-center" v-if="unit_pages > 1">
                <v-pagination
                  v-model="page"
                  :length="unit_pages"
                  :total-visible="7"
                ></v-pagination>
              </v-col>
            </div>
            <error-page :error="error" v-if="error"></error-page>
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "NoComments": "No comments written in the guestbook yet.",
    "LeaveComment": "Write in the guestbook...",
    "Guestbook": "Guestbook",
    "SuccessPublish": "Comment published!",
    "SuccessPublishAndModerate": "Your comment has been sent and is awaiting approval.",
    "ManageComments": "Manage comments"
    },
    "sv": {
    "NoComments": "Ingen har skrivit i gästboken ännu.",
    "LeaveComment": "Skriv i gästbok...",
    "Guestbook": "Gästbok",
    "SuccessPublish": "Kommentaren publicerades!",
    "SuccessPublishAndModerate": "Din kommentar har skickats och inväntar godkännande.",
    "ManageComments": "Hantera inlägg"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { guestbookService } from "@/_services";
import CommentItem from "@/components/diary/comments/CommentItem.vue";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
export default {
  name: "diary_guestbook",
  metaInfo() {
    return {
      titleTemplate:
        this.$t("Guestbook") +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content: this.$t("Guestbook") + " | " + this.diary.title,
          vmid: "og:title",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "comment-item": CommentItem,
    "user-avatar": UserAvatar,
  },
  data: () => ({
    dataService: guestbookService,

    page: 1,
    unit_pages: 1,
    comments: [],
    error: null,
    is_loading: false,

    comment: {
      text: "",
    },
    create_form: false,
    is_creating: false,
    create_error: null,
  }),
  created: function () {
    this.setFromUrl();
    this.loadComments();
  },
  methods: {
    loadComments() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      guestbookService
        .load(self.diary.name, self.page)
        .then(function (response) {
          self.comments = self.formatComments(response.data.data);
          self.unit_pages = response.data.unit_pages;
          self.page = response.data.current_page;
          self.is_loading = false;

          if (self.page > self.unit_pages && self.unit_pages > 0) {
            self.$router.push({ query: { page: 1 } });
          }
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    setFromUrl() {
      this.page = 1;

      if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    },
    formatComments(data) {
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        obj.link = "/" + this.diary.name + "/guestbook/" + obj.id;
      }

      return data;
    },
    createComment() {
      var self = this;
      self.is_creating = false;
      self.create_error = null;

      if (self.$refs.create_form.validate()) {
        self.is_creating = true;

        guestbookService
          .create(self.diary.name, self.comment)
          .then(function (response) {
            self.is_creating = false;
            self.comment.text = "";

            if (response.data.is_approved) {
              if (self.page == 1) {
                self.loadComments();
              } else {
                self.page = 1;
              }

              self.$successNoty(self.$t("SuccessPublish"));
            } else {
              self.$successNoty(self.$t("SuccessPublishAndModerate"));
            }

            self.$refs.create_form.resetValidation();
          })
          .catch(function (error) {
            self.create_error = error;
            self.is_creating = false;
          });
      }
    },
    onUserBlocked(username) {
      this.changeUserBlockStatus(username, true);
    },
    onUserUnblocked(username) {
      this.changeUserBlockStatus(username, false);
    },
    onAnswerDeleted(id) {
      for (var i = 0; i < this.comments.length; i++) {
        var obj = this.comments[i];

        if (obj.answers) {
          for (var j = 0; j < obj.answers.length; j++) {
            var ans = obj.answers[j];

            if (ans.id == id) {
              obj.answers.splice(j, 1);
            }
          }
        }
      }
    },
    changeUserBlockStatus(username, status) {
      this.comments.forEach((comment) => {
        if (comment.user.username == username) {
          comment.user.is_blocked = status;
        }
      });
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadComments();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.page) return;
      self.$router.push({ query: { page: self.page } });
    },
  },
};
</script>