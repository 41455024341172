<template>
  <div :style="backgroundStyle" class="fill-height default-bg">
    <v-container v-if="!error" class="diary-master pt-0 pb-12">
      <v-skeleton-loader :loading="is_loading" height="300" type="card-avatar">
        <v-container class="pt-0 mb-12 diary-master-container">
          <template v-if="visit_status == $enums.DIARYVISITSTATUS.OK">
            <diary-top></diary-top>
            <div class="diary-main">
              <router-view :key="diary.url_name"></router-view>
            </div>
          </template>
          <status-private
            v-if="visit_status == $enums.DIARYVISITSTATUS.PRIVATE"
          ></status-private>
          <status-password
            v-if="visit_status == $enums.DIARYVISITSTATUS.REQUIREPASSWORD"
          ></status-password>
          <status-session
            v-if="visit_status == $enums.DIARYVISITSTATUS.REQUIREAUTHENTICATION"
          ></status-session>
        </v-container>
      </v-skeleton-loader>
    </v-container>
    <error-page v-if="error" :error="error"></error-page>
  </div>
</template>

<i18n>
    {
    "en": {
    "Home": "Home",
    "Loading": "Loading",
    "DefaultDescription": "Read diary, '{name}' on Babiry"
    },
    "sv": {
    "Home": "Hem",
    "Loading": "Laddar",
    "DefaultDescription": "Läs dagboken, '{name}' på Barndagboken"
    }
    }
</i18n>

<script>
import Password from "@/components/diary/visitstatus/Password.vue";
import Private from "@/components/diary/visitstatus/Private.vue";
import RequireAuthentication from "@/components/diary/visitstatus/RequireAuthentication.vue";

import Top from "@/components/diary/main/Top.vue";
import { diaryTokenStore } from "@/store/browser";

import { mapState, mapActions } from "vuex";

export default {
  name: "diary_master",
  metaInfo() {
    return this.meta;
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      styles: (state) => state.diary.styles,
      temp: (state) => state.diary.temp,
      visit_status: (state) => state.diary.visit_status,
      is_admin: (state) => state.diary.is_admin,
    }),
    backgroundStyle: function () {
      var style = null;

      if (this.temp && this.temp.hasOwnProperty("background_color")) {
        style = this.temp;
      } else if (this.styles && this.styles.background) {
        style = this.styles.background;
      }
      if (style == null) return "";

      var data = "";

      if (style.background_color) {
        data += "background-color: " + style.background_color + ";";
      }
      if (style.background_image) {
        data += "background-image: url( " + style.background_image + ");";
      }
      if (style.background_image_repeat) {
        var backgroundRepeat = style.background_image_repeat.toLowerCase();

        if (backgroundRepeat === "r") {
          data += "background-position: center 0;";
          data += "background-repeat: repeat;";
        } else if (backgroundRepeat === "h") {
          data += "background-position: left 0;";
          data += "background-repeat: repeat-y;";
        } else if (backgroundRepeat === "hc") {
          data += "background-position: center 0;";
          data += "background-repeat: repeat-y;";
        } else if (backgroundRepeat === "hr") {
          data += "background-position: right 0;";
          data += "background-repeat: repeat-y;";
        } else if (backgroundRepeat === "v") {
          data += "background-position: left 0;";
          data += "background-repeat: repeat-x;";
        } else if (backgroundRepeat === "0") {
          data += "background-position: center 0;";
          data += "background-repeat: no-repeat;";
        } else if (backgroundRepeat === "01") {
          data += "background-position: left 0;";
          data += "background-repeat: no-repeat;";
        } else if (backgroundRepeat === "0r") {
          data += "background-position: right 0;";
          data += "background-repeat: no-repeat;";
        } else if (backgroundRepeat === "c") {
          data += "background-position: center;";
          data += "background-repeat: no-repeat;";
          data += "background-size: cover;";
        }
      }

      return data;
    },
  },
  components: {
    "status-private": Private,
    "status-password": Password,
    "status-session": RequireAuthentication,
    "diary-top": Top,
  },
  data: () => ({
    is_loading: false,
    error: null,
    title: "",
    meta: {},
  }),
  created: function () {
    this.init();
  },
  methods: {
    ...mapActions({
      authorizeDiary: "diary/authorize",
      loadDiary: "diary/load",
    }),
    init() {
      this.setLoadingMeta();
      this.loadData(this.$route.params.diary);
    },
    loadData(name) {
      var self = this;
      self.error = null;
      self.is_loading = true;

      if (diaryTokenStore.hasValidToken(name)) {
        self
          .loadDiary(name)
          .then(function () {
            self.setDiaryMeta();
            self.is_loading = false;
          })
          .catch(function (error) {
            self.handleError(error);
          });
      } else {
        self
          .authorizeDiary(name)
          .then(function () {
            self.setDiaryMeta();
            self.is_loading = false;
          })
          .catch(function (error) {
            self.handleError(error);
          });
      }
    },
    handleError(error) {
      if (error.response && error.response.status == 404) {
        this.$router.push("/error/404");
        return;
      } else {
        this.error = error;
        this.is_loading = false;
      }
    },
    setDiaryMeta() {
      var self = this;

      var desc = this.$t("DefaultDescription", { name: self.diary.title });

      if (this.visit_status == this.$enums.DIARYVISITSTATUS.OK) {
        if (self.diary.about && self.diary.about != "") {
          desc = self.diary.about;
        }

        this.meta = {
          title: this.$t("Home"),
          titleTemplate:
            "%s - " + self.diary.title + " | " + process.env.VUE_APP_NAME,
          meta: [
            {
              property: "og:title",
              content: self.diary.title,
              vmid: "og:title",
            },
            {
              name: "description",
              content: desc,
              vmid: "description",
            },
            {
              property: "og:description",
              content: desc,
              vmid: "og:description",
            },

            {
              property: "og:image",
              content: self.getImage(),
              vmid: "og:image",
            },
            {
              property: "og:image:width",
              content: "1200",
              vmid: "og:image:width",
            },
            {
              property: "og:image:height",
              content: "300",
              vmid: "og:image:height",
            },
            {
              property: "twitter:image:src",
              content: self.getImage(),
              vmid: "twitter:image:src",
            },
          ],
        };
      } else {
        this.meta = {
          title: "",
          titleTemplate: self.diary.title + " | " + process.env.VUE_APP_NAME,
          meta: [
            {
              property: "og:title",
              content: self.diary.title,
              vmid: "og:title",
            },
          ],
        };
      }
    },
    setLoadingMeta() {
      this.meta = {
        title: this.$t("Loading"),
        titleTemplate: "%s - " + process.env.VUE_APP_NAME,
      };
    },
    getImage() {
      var banner = this.diary.banner;
      var avatar = this.diary.avatar;

      if (banner) return banner.width_1200;

      if (avatar) return avatar.thumb_300;

      return null;
    },
  },
  watch: {
    $route() {
      if (this.diary.name != this.$route.params.diary) {
        this.init();
      }
    },
  },
};
</script>

<style>
.diary-page {
  padding: 0;
  margin-top: 20px;
}
.diary-page > .row > .col-12,
.diary-page .diary-master-main > .col-12 {
  padding-top: 0 !important;
  margin-top: 0;
}

@media only screen and (max-width: 768px) {
  .diary-master,
  .diary-master-container {
    padding: 0 !important;
  }

  .diary-master {
    margin: 0 !important;
  }
}
</style>